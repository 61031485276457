<template>
  <div class="apply">
     <navBar></navBar>
    <div class="content">
      <table style="width: 100%">
        <tr>
          <td>
            <h2 class="md-title">Deutsch</h2>
            <md-field>
              <label>Überschrift</label>
              <md-input v-model="applyText.de.title"></md-input>
            </md-field>
            <md-field>
              <label>Text</label>
              <md-textarea v-model="applyText.de.content" md-autogrow ></md-textarea>
            </md-field>
          </td>

          <td>
            <h2 class="md-title">English</h2>
            <md-field>
              <label>Header</label>
              <md-input v-model="applyText.en.title"></md-input>
            </md-field>
            <md-field>
              <label>Text</label>
              <md-textarea v-model="applyText.en.content" md-autogrow ></md-textarea>
            </md-field>
          </td>
        </tr>
      </table>

      <loading :active.sync="query"
               :can-cancel="true"
               :is-full-page="true"></loading>
      <md-button class="md-primary md-raised" @click="putText">Save</md-button>
    </div>
  </div>
</template>
<script>

    import navBar from '@/components/NavBar.vue';
    import axios from 'axios'
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import config from '@/config/const.js'

    export default {
        name: "Apply",
        data () {
            return {
              config: config,
              applyText: null,
              query: false,

              newApplyText: {
                titleIntern: "apply",
                "de": {
                  title: "",
                  content: ""
                },
                "en": {
                  title: "",
                  content: ""
                },
                site: "apply",
              },
            }
        },
        methods:{
          loadData: function () {
            const me = this
            me.query = true
            axios.get(me.config.SERVER_ADDRESS + '/text/name/apply')
              .then(function (response) {
                me.applyText = response.data[0]
                if (!me.applyText)
                  me.applyText = me.newApplyText
              })
              .catch(function (error) {
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
            me.query = false
          },
          putText(){
            if (this.applyText._id == null)
              this.postText()
            this.query = true;
            let me = this;
            axios.put(me.config.SERVER_ADDRESS + '/text/id/'+me.applyText._id,me.applyText)
              // eslint-disable-next-line no-unused-vars
              .then(function (response) {
                //console.log(response.data.message);
                me.resetSite()
                me.loadData()
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
                me.query = false;
              })
          },
          postText(){
            //console.log(this.applyText)
            this.query = true;
            let me = this;
            axios.post(me.config.SERVER_ADDRESS + '/text',me.applyText)
              // eslint-disable-next-line no-unused-vars
              .then(function (response) {
                //console.log(response.data.message);
                me.resetSite()
                me.loadData()
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
                me.query = false;
              })
          },
          resetSite: function () {
            this.applyText = null
            this.loadData()
          },

        },
        components: {
          navBar,
          Loading
        },
        mounted(){
          this.loadData()
        }
    }
</script>
<style scoped>
  .content{
    margin: 2% 5% 0 5%;
  }
  .head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  td{
    padding: 1%;
    width: 50%;
  }
  .noMargin{
    margin: 0px;
  }
</style>
